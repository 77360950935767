<template>
  <div class="equipment-tag-print-container">
    <DsnPanel>
      <div slot="header" class="title clearfix">
        <el-form
            :inline="true"
            :model="queryParam"
            ref="testQueryForm"
            :rules="{}"
            @submit.native.prevent
        >
          <el-form-item label="主分类:" prop="equipmentCategory">
            <dsn-input v-model="queryParam.equipmentCategory" placeholder="请输入分类名称" size="medium"></dsn-input>
          </el-form-item>
          <el-form-item label="子分类:" prop="equipmentSubcategory">
            <dsn-input v-model="queryParam.equipmentSubcategory" placeholder="请输入子分类名称" size="medium"></dsn-input>
          </el-form-item>
          <el-form-item>
            <dsn-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleQuery(['testQueryForm'])"
            >{{ this.$t('commbtn.query') }}
            </dsn-button>
            <dsn-button
                size="medium"
                type
                plain
                icon="el-icon-refresh"
                class="reset-button"
                @click="handleReset('materilList')"
            >{{ this.$t('commbtn.reset') }}
            </dsn-button>
          </el-form-item>
          <el-row>
            <el-col>
              <el-form-item label="打印数量:" prop="printCount">
                <dsn-input v-model="queryParam.printCount" placeholder="请输入打印数量" size="medium"></dsn-input>
              </el-form-item>
              <el-form-item label="条码类型:" prop="printCount">
                <dsn-input v-model="queryParam.barCodeType" placeholder="请输入打印数量" size="medium"></dsn-input>
              </el-form-item>
              <el-form-item>
                <dsn-button
                    size="medium"
                    type="primary"
                    icon="el-icon-print"
                    @click="()=>{}"
                >打印未使用标签
                </dsn-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <div class="operation">
        <dsn-button
            size="medium"
            type="success"
            icon="el-icon-folder-add"
            @click.native="handleAdd"
        >{{ this.$t('commbtn.add') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="primary"
            icon="el-icon-edit"
            :disabled="selectionList.length !== 1"
            @click="handleEdit"
            style="margin-left:0"
        >{{ this.$t('commbtn.edit') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="plain"
            icon="el-icon-document"
            :disabled="selectionList.length !== 1"
            @click="handlePrintCode"
            style="margin-left:0"
        >打印未使用标签
        </dsn-button>
        <dsn-button
            size="medium"
            type="danger"
            icon="el-icon-delete"
            style="margin-left:0"
            :disabled="selectionList.length <= 0"
            @click="handleDeleteCheck"
        >{{ this.$t('commbtn.delete') }}
        </dsn-button>
      </div>

      <dsn-table
          @row-click="selectCheckBox"
          ref="multipleTable"
          :data="listData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"/>
        <!--        <el-table-column type="index" label="序号"/>-->
        <el-table-column prop="equipmentCategory" label="主分类" width="200" align="center"/>
        <el-table-column prop="equipmentSubcategory" label="子分类" width="200" align="center"/>
        <el-table-column prop="genTotalCount" label="已生成标签数量" width="200" align="right"/>
        <el-table-column prop="unUseCount" label="未使用标签数量" width="200" align="right"/>
        <el-table-column/>
      </dsn-table>
      <DsnFooter>
        <dsn-pagination
            v-if="showPage"
            background
            layout="->,total,prev,pager,next,sizes"
            :total="pagingConfig.total"
            :page-size="pagingConfig.pageSize"
            :page-sizes="[5, 10, 15, 20]"
            :current-page="pagingConfig.current"
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentChange"
        ></dsn-pagination>
      </DsnFooter>
    </DsnPanel>
    <el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
      <span>是否确认删除{{ selectionList.length }}条数据？</span>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="deleteDialog = false">取 消</dsn-button>
        <dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
import DsnFooter from "@/views/layout/dsn-footer";
import {getUserList} from "@/api/efms/users/users.api";

export default {
  name: 'equipmentBarcodePrint',
  components: {DsnFooter},
  inject: ["defaltDialogWidth"],
  data() {
    return {
      listData: [],
      queryParam: {
        username: null,
        realname: null
      },
      showPage: true,
      pagingConfig: {
        current: 1,
        pageSize: 10,
        count: 0,
        tableLoading: false,
        total: 0
      },
      deleteDialog: false,
      selectionList: [],
    }
  },
  computed: {},
  created() {
    this.getSysUserList();
  },
  activated() {
    this.getSysUserList();
  },
  methods: {
    ...mapMutations(["USERSLIST"]),
    handleQuery(formNames) {
      this.$refs[formNames].validate(valid => {
        if (valid) {
          this.getSysUserList();
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
      this.handleQuery();
    },
    handlePageSizeChange(pageSize) {
      this.pagingConfig.pageSize = pageSize;
      this.pagingConfig.current = 1;
      this.getSysUserList();
    },
    handleCurrentChange(currentChange) {
      this.pagingConfig.current = currentChange;
      this.getSysUserList();
    },
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    selectCheckBox(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleEdit() {
      const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.USERSLIST(tempArr);
      localStorage.setItem("userList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "equipmentBarcodeEdit",
        query: {operateType: "edit"}
      });
    },
    handleAdd() {
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.USERSLIST([]);
      // localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "equipmentBarcodeAdd",
        query: {operateType: "add"}
      });
    },
    handleDelete() {
      const {selectionList} = this;
      const params = selectionList.map(item => ({
        id: item.id,
      }));
      console.info(params)
    },
    handleDeleteCheck() {
      this.messageBox()
          .then(() => {
            this.handleDelete();
          })
          .catch(() => {
            this.notification().warn({
              body: "已经取消删除"
            });
          });
    },
    getSysUserList() {
      console.info("query")
      this.initFakeData();
      this.initMainList();
    },
    initFakeData() {
      for (let i = 0; i <= 100; i++) {
        this.listData.push({
          id: i,
          equipmentCategory: '电脑' + i,
          equipmentCategoryId: '电脑' + i,
          equipmentSubcategory: '子类目' + i,
          equipmentSubcategoryId: '子类目' + i,
          genTotalCount: 1000,
          unUseCount: 50
        })
      }
    },
    initMainList(showPage) {
      if (!showPage) {
        this.pagingConfig.currentPage = 1;
      }
      const data = {
        ...this.queryParam,
        currentPage: this.pagingConfig.currentPage,
        pageSize: this.pagingConfig.pageSize
      };
      this.showPage = showPage;
      console.info(data)
      getUserList(data).then((response) => {
        const {status, errMsg, data} = response;
        if (status === 200) {
          this.showPage = true;
          this.pagingConfig.total = data.total;
          this.pagingConfig.tableData = data.data;
          return;
        }
        this.notification().warn({
          body: errMsg
        });
      })
    },
    handlePrintCode() {
      let targetItem = this.selectionList[0]
      if (targetItem != null) {
        console.info(targetItem)
      }
    }

  }
}
</script>
<style lang="scss" scoped>

.equipment-tag-print-container {
  .operation {
    width: 100%;
    height: 48px;

    .dsn-button {
      margin: 0 8px 0 0;
    }
  }
}

::v-deep.el-table .cell {
  font-size: 18px;
}

</style>
