import request from "@/service/";


export const addEquipmentCategory = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/refs/categories`,
        data
    );
}

export const updateEquipmentCategory = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/refs/categories/${data.id}`,
        data
    );
}


export const getEquipmentCategoryList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/categories`,
        {
            params: params
        }
    );
}

export const getEquipmentCategoryById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/categories/${id}`,
    );
}

export const deleteOneCategory = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/refs/categories/${id}/delete`,
    );
}
