<template>
  <div class="tag-add-container">
    <div class="operate">
      <dsn-button
          size="small"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          @click.native="checkAdd(['userFormRef'])">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="small"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['userFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="tagForm"
          ref="userFormRef"
          :inline="true"
          :rules="tagFormRules"
          class="userFormTop"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item :label="this.$t('categories.fields.category')" prop="categoryId" :label-width="labelWidth">
              <dsn-select
                  v-model.trim="tagForm.categoryId"
                  filterable
                  :value-key="'id'"
                  :placeholder="`${this.$t('commmsg.pleaseSelect')}${this.$t('categories.fields.category')}`"
                  @change="onCategoryChange"
                  size="medium"
              >
                <el-option
                    v-for="item in mainCategoryList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                ></el-option>
              </dsn-select>
            </el-form-item>
            <el-form-item :label="this.$t('categories.fields.subcategory')" prop="categoryId"
                          :label-width="labelWidth">
              <dsn-select
                  v-model.trim="tagForm.subcategoryId"
                  filterable
                  :value-key="'id'"
                  :placeholder="`${this.$t('commmsg.pleaseSelect')}${this.$t('categories.fields.subcategory')}`"
                  @change="onSubcategoryChange"
                  size="medium"
              >
                <el-option
                    v-for="item in subcategoryOptions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                ></el-option>
              </dsn-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="this.$t('tags.fields.count')" prop="generateCount" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="tagForm.generateCount"
                  placeholder="生成数量"
                  :disabled="isEditStation"
              ></dsn-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-tabs type="border-card">
        <el-tab-pane class="schedule-detail-info">
          <span slot="label">{{ this.$t('tags.fields.tagDetail') }}</span>
          <div class="operation">
            <dsn-button
                size="small"
                type="success"
                icon="el-icon-folder-add"
                @click.native="previewGen"
            >预览生成
            </dsn-button>
            <dsn-button
                size="small"
                type="success"
                icon="el-icon-folder-add"
                @click.native="printAllQrCode"
            >Print AllQrCode
            </dsn-button>
          </div>
          <vxe-table
              border
              resizable
              show-overflow
              ref="scheduleItemTableRef"
              keep-source
              :data="previewList"
              height="500"
          >
            <vxe-table-column type="seq" width="60"></vxe-table-column>
            <vxe-table-column field="tagNo" :title="this.$t('equipment.fields.sno')"
                              :edit-render="{name: 'input', attrs: {type: 'text'}}" align="center"></vxe-table-column>
            <!--            <vxe-table-column title="img" field="tagNo" align="center">-->
            <!--              <template v-slot:default="{row}">-->

            <!--                <img v-if="row.tagNo!=null" :src="createBarcode(row.tagNo)" :ref="'img-ref'+row.tagNo"-->
            <!--                     style="width:auto; height:108px!important;"/>-->
            <!--                <span v-else>无</span>-->
            <!--              </template>-->
            <!--            </vxe-table-column>-->
            <vxe-table-column title="Qrcode" field="tagNo" align="center">
              <template v-slot:default="{row}">
                <!--                <img v-if="row.tagNo!=null" :src="createQrCode(row.tagNo)" :ref="'imgs-ref'+row.tagNo"-->
                <!--                     style="width:auto; height:108px!important;"/>-->
                <!--                <span v-else>无</span>-->
                <div>
                  <div id="testPrint" class="print-card-container">
                    <div style="font-size: 13px;font-weight: bold">深圳市德艺科技实业有限公司</div>
                    <div class="card-text">几码编号:xxxxxxxxxxx</div>
                    <div class="card-text">规格型号:xxxxxxxxxxx</div>
                    <div class="card-text">品牌:xxxx</div>
                    <div class="card-text">到厂日期:xxxx</div>
                    <div class="card-text">资产编号:xxxx</div>
                  </div>
                </div>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>
<script>
import {addUser} from "@/api/efms/users/users.api";
import DsnSelect from "@/components/global/Select/DsnSelect";
import {getEquipmentCategoryList} from "@/api/efms/equipments/equipment.category.api";
import arrayToTree from "array-to-tree";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import printJS from "print-js";

export default {
  name: 'tagAdd',
  components: {DsnSelect},
  data() {
    return {
      isEditStation: false, //是否可编辑站位
      tagForm: {
        categoryId: null,
        categoryCode: null,
        subcategoryCode: null,
        subcategoryId: null,
        generateCount: 1
      },
      labelWidth: "100px",
      tagFormRules: {
        categoryId: [{required: true, message: "请选择主记录", trigger: "change"}],
        subcategoryId: [{required: true, message: "请选择子记录", trigger: "change"}],
        generateCount: [{required: true, message: "请输入生成数量", trigger: "change"}],
      },
      mainCategoryList: [],
      subcategoryOptions: [],
      previewList: []
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.getMainCategoryList();
  },
  methods: {
    handleBack() {
      this.$router.pushAndClear(
          {name: "userList"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      postData['username'] = this.userForm.username
      postData['password'] = this.userForm.password
      postData['realname'] = this.userForm.realname
      postData['sex'] = this.userForm.sex
      postData['email'] = this.userForm.email
      postData['phone'] = this.userForm.phone
      addUser(postData).then(result => {
        const {code, errMsg} = result.data;
        if (code === 200) {
          this.notification().success({
            body: "保存成功",
          });
          this.handleBack();
          return;
        } else {
          this.notification().error({
            body: errMsg,
          });
        }
      }).catch(err => {
        this.notification().error({
          body: this.$t('errors.fields.serverInternalError'),
        });
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    },
    onCategoryChange(value) {
      console.info(value)
      let targetArray = this.mainCategoryList.filter(s => s.id === value)
      if (targetArray != null && targetArray.length === 1) {
        this.tagForm.categoryCode = targetArray[0].code;
        let childrenNodes = targetArray[0].children;
        if (childrenNodes != null && childrenNodes.length > 0) {
          this.subcategoryOptions = [];
          this.subcategoryOptions = [...childrenNodes]
        } else {
          this.subcategoryOptions = [];
        }
      } else {
        this.tagForm.categoryCode = null;

      }
      console.info(targetArray);
    },
    onSubcategoryChange(value) {
      console.info(value)
      if (value === null) {
        this.tagForm.subcategoryId = null;
        this.tagForm.subcategoryCode = null;
      } else {
        if (this.subcategoryOptions == null) {
          this.tagForm.subcategoryId = null;
          this.tagForm.subcategoryCode = null;
        } else {
          let targetArray = this.subcategoryOptions.filter(s => s.id === value)
          if (targetArray != null && targetArray.length === 1) {
            this.tagForm.subcategoryCode = targetArray[0].code;
          } else {
            this.tagForm.subcategoryCode = null;
          }
        }
      }
    },
    getMainCategoryList() {
      let params = {};
      params["page"] = 0;
      params["pageSize"] = 10;
      getEquipmentCategoryList(params)
          .then(res => {
            const {status, data} = res;
            if (status === 200 && data.data != null) {
              let tempList = data.data;
              if (tempList.length > 0) {
                let treeData = arrayToTree(tempList, {
                  parentProperty: "parentId",
                  childrenProperty: 'children'
                });

                let tempOptionList = [];
                treeData.forEach((v) => {
                  let optionData = {
                    id: v.id,
                    label: v.categoryName,
                    key: v.id,
                    code: v.categoryCode,
                    children: [],
                  };
                  if (v.children != null && v.children.length > 0) {
                    v.children.forEach((i) => {
                      let childNode = {
                        id: i.id,
                        label: i.categoryName,
                        value: i.id,
                        code: i.categoryCode,
                      }
                      optionData['children'].push(childNode);
                    })
                  }
                  tempOptionList.push(optionData);
                })
                this.mainCategoryList = [...tempOptionList]
                console.info(tempOptionList);
              }
            } else {
              console.info("info")
            }
          })
          .catch(err => {

          })
    },
    previewGen() {
      console.info(this.tagForm);
      if (this.tagForm.categoryId != null) {
        if (this.tagForm.subcategoryId != null) {
          let subcategoryCode = this.tagForm.subcategoryCode;
          let codeList = [this.tagForm.generateCount];
          for (let i = 0; i < this.tagForm.generateCount; i++) {
            codeList[i] = {
              tagNo: subcategoryCode + (i + 1),
              status: 0
            }
          }
          this.previewList = [...codeList];
        }
      }
    },
    createBarcode(tagNo) {
      console.log(tagNo);
      console.log("触发条码生成事件");
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, tagNo, {
        format: "CODE128",
        displayValue: true,
        margin: 10,
        height: 48,
        width: 3,
        fontSize: 30,
        textMargin: 10,
      });
      return canvas.toDataURL("image/png");
    },
    createQrCode(tagNo) {
      let canvas = document.createElement('canvas')
      QRCode.toCanvas(canvas, tagNo, function (error) {
        if (error) console.error(error)
        console.log('success!');
      })
      return canvas.toDataURL("image/png");
    },
    printAllQrCode() {

      let data = this.previewList.map((v) => {
        let canvas = document.createElement('canvas')
        QRCode.toCanvas(canvas, v.tagNo, function (error) {
          if (error) console.error(error)
          console.log('success!');
        })
        return canvas.toDataURL("image/png")
      });
      printJS({
        // 要打印的内容 放在了data里
        printable: 'testPrint',
        // 打印类型 目前为图片样式 可以根据上面的网址进行修改
        type: 'html',

        // 二维码样式 可以自己进行修改
        style: this.getPrintStyle()
        // 也可以设置以下参数 继承所有css样式 没试过image的 html的效果不错
        // targetStyles:['*']
      })
    },
    getPrintStyle() {
      return `
      .print-card-container{
             border-radius: 10px;border: 1px solid black;width: 250px!important;height: 130px
       }
       .card-text {
  text-align: left;
  font-size: 5px;
  line-height: 18px;
  margin-left: 10px !important;
}
       `
    }
  }
}
</script>
<style lang="scss" scoped>

.tag-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .userFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .userForm {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }
}

::v-deep.vxe-table .vxe-body--column.col--ellipsis {
  height: 180px;
}

::v-deep .vxe-cell .c--tooltip {
  height: 180px;
}

::v-deep.vxe-table .vxe-body--column:not(.col--ellipsis), .vxe-table .vxe-footer--column:not(.col--ellipsis), .vxe-table .vxe-header--column:not(.col--ellipsis) {
  padding: 5px 0;
  height: 180px;
}

::v-deep .vxe-table--render-default .vxe-body--column.col--ellipsis > .vxe-cell, .vxe-table--render-default .vxe-footer--column.col--ellipsis > .vxe-cell, .vxe-table--render-default .vxe-header--column.col--ellipsis > .vxe-cell {
  max-height: 180px;
}

.card-text {
  text-align: left;
  font-size: 5px;
  line-height: 18px;
  margin-left: 10px !important;
}

.print-card-container {
  border-radius: 10px;
  border: 1px solid black;
  width: 250px;
  height: 130px
}
</style>
